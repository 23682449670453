<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <Header2></Header2>
      <div class="distributor-container-mobile">
        <div class="category-header-mobile">
          <h1 class="category-title-mobile">{{ $t("footer-you-know") }}</h1>
        </div>
      </div>
      <div class="items-container-mobile">
        <div
          class="item-box-mobile"
          v-for="(item, index) in postLists.items"
          :key="index"
        >
          <img
            v-if="item.images.some((image) => image.type === 'Original')"
            :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${item.images[0].id}&width=416&height=301&isKeepAspectRatio=true`"
            class="img-doyouknow-mobile"
            alt=""
          />
          <img
            v-else
            src="@/assets/img/image-default.png"
            class="img-loading-mobile"
            alt="Not loading"
          />
          <p class="item-title-mobile">
            {{ item.description }}
          </p>
          <router-link
            :to="{ path: `/didyouknowproduct/${item.id}` }"
            class="doyouknow-router-mobile"
            >{{ $t("more-info-btn") }}
            <img
              src="@/assets/img/doyouknow-arrow.svg"
              class="doyouknow-arrow"
              alt=""
          /></router-link>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <Header2></Header2>
      <div class="distributor-container">
        <div class="category-header">
          <h1 class="category-title">{{ $t("footer-you-know") }}</h1>
        </div>
      </div>
      <div class="items-container">
        <div
          v-for="(item, index) in postLists.items"
          :key="index"
          class="item-box"
        >
          <!-- Содержимое первой колонки -->
          <img
            v-if="item.images.some((image) => image.type === 'Original')"
            :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${item.images[0].id}&width=416&height=301&isKeepAspectRatio=true`"
            class="img-doyouknow"
            alt=""
          />
          <img
            v-else
            src="@/assets/img/image-default.png"
            class="img-loading"
            alt="Not loading"
          />
          <p class="item-title">
            {{ item.description }}
          </p>

          <router-link
            :to="{ path: `/didyouknowproduct/${item.id}` }"
            class="doyouknow-router"
            >{{ $t("more-info-btn") }}
            <img
              src="@/assets/img/doyouknow-arrow.svg"
              class="doyouknow-arrow"
              alt=""
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import Header2 from "@/components/Header2.vue";

export default {
  name: "Didyouknow",

  components: {
    Header2,
  },

  data() {
    return {
      postLists: {
        items: [
          {
            htmlBody: "",
            // другие данные
          },
          // Добавьте другие элементы по мере необходимости
        ],
      },
    };
  },

  methods: {
    getPostList() {
      const locale = localStorage.getItem("app_locale");
      this.$http
        .put("/api/Post/PagedList", {
          // pageNumber: this.currentPage,
          // pageSize: 100,
          sorting: {
            property: "SortOrder",
            isDescending: false,
          },
          headers: {
            CurrentLanguage: locale,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.postLists = response.data;
          this.imgPathBase = process.env.VUE_APP_API_HOST;
        })
        .catch((error) => (this.error = error));
    },
  },
  created() {
    this.getPostList();
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale; // Получаем текущую локаль из хранилища Vuex
    },
  },
  watch: {
    currentLocale: {
      handler() {
        // Выполняйте запросы к API при изменении локали
        this.getPostList(); // Передаем новую локаль в функцию для обновления данных
        // Передаем новую локаль в другую функцию для обновления данных
      },
      immediate: true, // Вызывать обработчик сразу после создания компонента
    },
  },
};
</script>
  
  <style scoped>
.img-loading-mobile {
  max-width: fit-content;
  width: 100%;
}
.img-loading {
  width: 416px;
  height: 300px;
}
.doyouknow-router-mobile {
  display: flex;
  align-items: center;
  color: var(--04, #c37d46);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 21px;
}
.item-title-mobile {
  margin-top: 32px;
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
  min-height: 74px;
}
.img-doyouknow-mobile {
  width: 100%;
  height: auto;
}
.doyouknow-router {
  color: var(--04, #c37d46);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  display: flex;
  margin-top: 41px;
  text-decoration: none;
}
.doyouknow-arrow {
  margin-left: 10px;
}
.img-doyouknow {
  max-width: 416px;
  width: 100%;
  max-height: 301px;
  height: 100%;
}
.item-box-mobile {
  /* text-align: center; */
  margin-bottom: 60px;
}

.card-img-mobile {
  width: 124px;
  height: 155px;
}
.items-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 118px;
}
.category-header-mobile {
  margin-top: 19px;
  margin-bottom: 25px;
}
.search-component__input-mobile {
  border-radius: 100px;

  border: 1px solid var(--Gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.category-title-mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  /* margin-bottom: 25px; */
}
.distributor-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
}

.item-box {
  flex-grow: 0;
  flex-basis: 416px;
  flex-grow: 0;
  flex-basis: 416px;
  margin-right: 10px; /* Отступ справа между элементами */
  margin-left: 10px;
  margin-bottom: 40px; /* Отступ снизу между элементами */
}
.item-country {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-top: 10px;
}
.item-title {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: initial;
  margin-top: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
  min-height: 74px;
}
.items-container {
  display: flex;
  /* margin-top: 20px;
  margin-bottom: 177px; */
  width: 1310px;
  /* padding: 12px; */
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
}
.search-component__input {
  border-radius: 100px;

  border: 1px solid var(--Gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 10.5px;
  right: 15px;
}
.search-component {
  max-width: 606px;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.category-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  width: 100%; /* Распределение ширины 50% для h1 */
}
.distributor-container {
  display: flex;
  margin-top: 38px;
  margin-bottom: 40px;
  width: 1310px;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1263px) and (min-width: 577px) {
  .items-container-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item-box-mobile {
    width: calc(
      49% - 10px
    ); /* Устанавливаем ширину в 50% с небольшим отступом */
    margin-right: 10px; /* Отступ между карточками (можете настроить по своему усмотрению) */
    margin-bottom: 40px; /* Отступ снизу (можете настроить по своему усмотрению) */
  }
}
</style>
  
  